import QRCodeStyling from "./components/Shared/QRCodeStyling.js"
import PWAPrompt from "./components/Shared/PWAPrompt.js"
import ModalDialogWithButtons from "./components/Shared/ModalDialogWithButtons.js"
import ModalDialog from "./components/Shared/ModalDialog.js"
import IconComponent from "./components/Shared/Icon.js"
import ModalSlider from "./components/Shared/ModalSlider.js"
import NewsletterPublicComponent from "./components/Shared/NewsletterPublicComponent.js"
import Toast from "./components/Shared/Toast.js"
import OptionsDialog from "./components/Shared/OptionsDialog.js"
import BoondsPicker from "./components/Shared/BoondsPicker.js"
import PublicQRCode from "./components/Shared/PublicQRCode.js"
import ShareOptions from "./components/Shared/ShareOptions.js"
import DomainNameForm from "./components/Shared/DomainNameForm.js"

if (!customElements.get("boonds-picker")) {
  customElements.define("boonds-picker", BoondsPicker)
}

if (!customElements.get("modal-dialog-with-buttons")) {
  customElements.define("modal-dialog", ModalDialog)
  customElements.define("modal-dialog-with-buttons", ModalDialogWithButtons)
}

if (!customElements.get("icon-component")) {
  customElements.define("icon-component", IconComponent)
}

if (!customElements.get("pwa-install-prompt")) {
  customElements.define("pwa-install-prompt", PWAPrompt)
}

if (!customElements.get("modal-slider")) {
  customElements.define("modal-slider", ModalSlider)
}

if (!customElements.get("toast-notification")) {
  customElements.define("toast-notification", Toast)
}

if (!customElements.get("newsletter-public-component")) {
  customElements.define(
    "newsletter-public-component",
    NewsletterPublicComponent
  )
}
if (!customElements.get("options-dialog")) {
  customElements.define("options-dialog", OptionsDialog)
}

if (!customElements.get("qr-code-styling")) {
  customElements.define("qr-code-styling", QRCodeStyling)
}

if (!customElements.get("public-qr-code")) {
  customElements.define("public-qr-code", PublicQRCode)
}

if (!customElements.get("share-options")) {
  customElements.define("share-options", ShareOptions)
}

if (!customElements.get("domain-name-form")) {
  customElements.define("domain-name-form", DomainNameForm)
}
