import ColorPicker from "../AtomLinksList/ColorPicker.js"

export default class NewsletterPublicComponent extends HTMLElement {
  constructor() {
    super()
  }
  connectedCallback() {
    this.config = window.atom_dict.atom_links[this.dataset.index]
    this.config.fields = this.config.special_value.split(",").filter((i) => i)
    this.layout = null
    if (this.config.fields.length === 0) {
      return
    }
    this._template()
    this._styles()
    this._listenForm()
    this.submitButton = this.querySelector('button[type="submit"]')
  }

  _translationsMap = {
    email: gettext("Email"),
    phone: gettext("Phone"),
    address: gettext("Address"),
    name: gettext("Name"),
    text: gettext("Message"),
  }

  static csrftoken = document.querySelector("[name=csrfmiddlewaretoken]").value

  _template() {
    if (!this.layout || this.layout === "default") {
      this.innerHTML = `
                <div class="boonddescription load-animation container">
                    <div class="title">
                        <h4>${this.config.description || ""}</h4>
                    </div>
                    <form class="form">
                        <div class="leads-list" id="list${this.dataset.index}">
                            ${this.config.fields
                              .filter((f) => f)
                              .map((field) => {
                                if (field === "text" || field === "address") {
                                  return `
                                        <textarea class="boonddescription" id="newsletter-${this.dataset.index}-${field}" placeholder="${this._translationsMap[field]}"></textarea>
                                    `
                                }
                                return `
                                        <input class="boonddescription" id="newsletter-${this.dataset.index}-${field}" type="text" placeholder="${this._translationsMap[field]}">
                                    `
                              })
                              .join("")}
                        </div>
                        <button
                            class="newsletter-btn boonddescription ga4-track-user-analytics"
                            type="submit"
                            data-ga4-atombio-element-id="${this.config.id}"
                            data-ga4-type="newsletter"
                            data-ga4-sub-type="email"
                            data-ga4-text-content="${this.config.description_sub || ""}"
                        >${this.config.description_sub || gettext("Subscribe")}</button>
                    </form>
                </div>
            `
    }
  }

  _listenForm() {
    this.querySelector("form").addEventListener(
      "submit",
      this._handleSubmit.bind(this)
    )
    this.querySelectorAll("textarea").forEach((textarea) => {
      textarea.addEventListener('input', () => {
        // Reset the height to recalculate the correct scroll height
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      });
    })
  }

  async _handleSubmit(e) {
    e.preventDefault()
    const validation = Array.from(e.target.elements)
      .filter((element) => element.tagName === "INPUT")
      .every((v) => v.value)
    if (!validation) {
      Toast.showToast(gettext("Please fill all the fields"), { type: "error" })
      return
    }
    try {
      const r = await fetch(window.location.origin + "/lead/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": NewsletterPublicComponent.csrftoken,
        },
        body: JSON.stringify({
          name:
            this.querySelector(`input#newsletter-${this.dataset.index}-name`)
              ?.value || null,
          email:
            this.querySelector(`input#newsletter-${this.dataset.index}-email`)
              ?.value || null,
          phone:
            this.querySelector(`input#newsletter-${this.dataset.index}-phone`)
              ?.value || null,
          address:
            this.querySelector(
              `textarea#newsletter-${this.dataset.index}-address`
            )?.value || null,
          text:
            this.querySelector(`textarea#newsletter-${this.dataset.index}-text`)
              ?.value || null,
          url_origin: window.location.origin + window.location.pathname,
          atomlink_id: this.config.id,
        }),
      })
      const data = await r.json()
      if (data.success === 0) {
        throw data.response
      }
      Toast.showToast(gettext("Thanks for sending your contact details!"), {
        type: "success",
      })
      this.submitButton.innerHTML = gettext("Subscribed")
      this.submitButton.style.pointerEvents = "none"
    } catch (err) {
      console.log(err)
      let messageString = ""
      Object.keys(err).forEach((key) => {
        messageString += `${err[key].join(", ")}\n`
      })
      Toast.showToast(messageString, { type: "error" })
    }
  }
  _styles() {
    const style = document.createElement("style")
    style.textContent = `
            * {
                font-family: ${window.atom_dict.config[0].font_family};
                font-size: 1rem;
            }
            h4 {
                font-weight: 800;
                font-size: 1rem;
            }
            :host {
                width: 100%;
                margin-top: 1rem;
            }
                
            newsletter-public-component[data-index="${this.dataset.index}"] .container {
                max-width: unset;
                background-color: ${this.config.background_color ? this.config.background_color : window.atom_dict.config[0].background_color};
                color: ${this.config.background_color ? ColorPicker.getContrast(this.config.background_color) : window.atom_dict.config[0].text_color};
            }

            newsletter-public-component[data-index="${this.dataset.index}"] .title {
              color: ${this.config.custom_color ? this.config.text_color : window.atom_dict.config[0].font_color};
            }

            newsletter-public-component[data-index="${this.dataset.index}"] input {
              background: transparent;
            }

            newsletter-public-component[data-index="${this.dataset.index}"] input::placeholder,
            newsletter-public-component[data-index="${this.dataset.index}"] textarea::placeholder {
              color: ${this.config.custom_color ? this.config.text_color : window.atom_dict.config[0].font_color};
            }

            .leads-list {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                flex-wrap: wrap;
                gap: 1rem;
            }

            newsletter-public-component[data-index="${this.dataset.index}"] .leads-list input,
            newsletter-public-component[data-index="${this.dataset.index}"] .leads-list textarea {
                width: 100%;
                font-size: 1rem;
                text-align: left;
                padding: 0.5rem;
                font-weight: 400;
                box-shadow: 0 0 0 0;
                border: 2px solid ${window.atom_dict.config[0].btn_border_color};
                color: ${this.config.custom_color ? this.config.text_color : window.atom_dict.config[0].font_color};
                margin: 0!important;
                padding-left: 1rem;
                background-color: #00000014;
            }

            .leads-list input:focus {
                outline: none;
            }

            newsletter-public-component[data-index="${this.dataset.index}"] form button.newsletter-btn.boonddescription {
                margin-top: 1rem;
                background-color: ${this.config.special_value_2 || "#750A97"}!important;
                color: ${this.config.special_value_2 ? ColorPicker.getContrast(this.config.special_value_2) : "white"}!important;
                border: none;
                padding: 0.5rem 1rem;
                font-weight: bold;
                font-size: 1rem;
                margin-bottom: 0rem!important;
            }
        `
    this.appendChild(style)
  }

  _getColumnCount() {
    if (this.config.fields.length === 1) {
      return 1
    }
    return 2
  }
}
